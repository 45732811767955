
import { ProjectEntityModel, ProjectQueryModel } from '@/entity-model/project-entity';
import { ICRUDQ } from '@/model/interface';
import { get, post, del, put } from './request';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/project`;
class ProjectService implements ICRUDQ<ProjectEntityModel, ProjectQueryModel> {
    async create(model: ProjectEntityModel):Promise<ProjectEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        delete params.logo;
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string):Promise<ProjectEntityModel> {
        const url = `${URL_PATH}/${modelId}`;
        const res = await get(url);
        return new ProjectEntityModel().toModel(res);
    }

    async update(model: ProjectEntityModel):Promise<ProjectEntityModel> {
        const url = `${URL_PATH}/save`;
        const params = model.toService();
        delete params.logo;
        const res = await post(url, params);
        return res;
    }

    async delete(model: ProjectEntityModel):Promise<ProjectEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: ProjectQueryModel, page?: number, limit?: number):Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        res.items = _.map(res.items, item => item = new ProjectEntityModel().toModel(item));
        return res;
    }

    async getAllProject():Promise<any> {
        const url = `${URL_PATH}/sauth/projects`;
        const res = await get(url, null, { cache: 600000 });
        return _.map(res, item => item = new ProjectEntityModel().toModel(item));
    }

    async saveWebInfo(projectId: string, webInfo: {loginBg: string, logo: string}):Promise<Boolean> {
        const url = `${URL_PATH}/loginInfo`;
        const res = await put(url, { id: projectId, ...webInfo });
        return res;
    }

    async saveWxInfo(projectId: string, params: {wxLoginBg: string, wxBanner: string}):Promise<Boolean> {
        const url = `${URL_PATH}/wxInfo`;
        const res = await put(url, { id: projectId, ...params });
        return res;
    }
}

export default new ProjectService();
